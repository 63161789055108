function isMobile() {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  return flag;
}

// 倒计时
function countDown(startTime, endTime) { // Date 类型参数
  let usedTime = endTime - startTime // 获取时间差 毫秒
  if (usedTime < 0) {
    return null
  }
  let times = Math.floor(usedTime / 1000) // 时间差转为秒
  let day, hour, minute, second = 0
  if (times > 0) {
    day = Math.floor(times / (60 * 60 * 24))
    hour = Math.floor(times / (60 * 60)) - (day * 24)
    minute = Math.floor(times / 60) - (day * 24 * 60) - (hour * 60)
    second = Math.floor(times) - (day * 24 * 60 * 60) - (hour * 60 * 60) - (minute * 60)
  }
  if (day <= 9) day = '0' + day
  if (hour <= 9) hour = '0' + hour
  if (minute <= 9) minute = '0' + minute
  if (second <= 9) second = '0' + second
  return {
    day,
    hour,
    minute,
    second
  }
}

export default {
  isMobile,
  countDown
}