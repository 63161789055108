import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import Vant from 'vant';
import 'vant/lib/index.css';
import axios from 'axios'
import VueAxios from 'vue-axios'
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import '@/theme/element/index.css'
import wxshare from '@/utils/wxShare'


Vue.config.productionTip = false

Vue.use(wxshare);
Vue.use(ElementUI);
Vue.use(Vant);
Vue.use(VueAxios, axios)

Vue.config.productionTip = false


const isRelease = false
if (isRelease) {
  axios.defaults.baseURL = 'https://api.idoctor.cc/BayerApi'
} else {
  axios.defaults.baseURL = 'http://101.200.177.46:8076'
}

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
