import wx from 'weixin-jsapi'

const wxshare = {}
wxshare.install = function (Vue) {
    Vue.prototype.shareList = function (link, desc, title) {
        // 分享
        var url = encodeURIComponent(location.href.split('#')[0])
        url = location.href.split('#')[0];
        console.log(url);
        Vue.axios.get('https://api.healsforce.com' + '/Api/Wechat/GetShareSignature', { params: { url: url } }).then(function (res) {
            
            res = res.data;
            // console.log(res.Ticket);   
            // console.log(res.TimeStamp);   
            // console.log(res.Signature);   
            // console.log(res.NonceStr);   

            wx.config({
                debug: false, // true:调试时候弹窗
                appId: 'wx656e9ab44ad43418', // 微信appid
                timestamp: res.TimeStamp, // 时间戳
                nonceStr: res.NonceStr, // 随机字符串
                signature: res.Signature, // 签名
                jsApiList: [
                    // 所有要调用的 API 都要加到这个列表中
                    'onMenuShareTimeline', // 分享到朋友圈接口
                    'onMenuShareAppMessage', //  分享到朋友接口
                    'onMenuShareQQ', // 分享到QQ接口
                    'onMenuShareWeibo', // 分享到微博接口
                    'onMenuShareAppMessage'
                ]
            })
            wx.checkJsApi({
                jsApiList: [
                    // 所有要调用的 API 都要加到这个列表中
                    'onMenuShareTimeline', // 分享到朋友圈接口
                    'onMenuShareAppMessage', //  分享到朋友接口
                    'onMenuShareQQ', // 分享到QQ接口
                    'onMenuShareWeibo', // 分享到微博接口
                    'onMenuShareAppMessage'
                ],
                success: function () {
                }
            })

            wx.ready(function () {
                const imgUrl = "https://cdn.821.idoctor.cc/wx/img/wx_thumb.jpg"; //https://821.idoctor.cc/wx/img/wx_thumb.jpg
                // 微信分享的数据
                var shareData = {
                    imgUrl: imgUrl, // 分享显示的缩略图地址
                    link: link, // 分享地址
                    desc: desc, // 分享描述
                    title: title, // 分享标题
                    success: function () {
                        // 分享成功可以做相应的数据处理
                        console.log('分享成功')
                        // console.log('appId:' + res.appId)
                        // console.log('timestamp:' + res.timestamp)
                        // console.log('nonceStr:' + res.nonceStr)
                        // console.log('signature:' + res.signature)
                    },
                    fail: function () {
                        console.log('调用失败')
                    },
                    complete: function () {
                        console.log('调用结束')
                    }
                }
                wx.onMenuShareAppMessage(shareData)
                // wx.updateTimelineShareData(shareData)
                // wx.updateAppMessageShareData(shareData)
                wx.onMenuShareQQ(shareData)
                wx.onMenuShareWeibo(shareData)
                wx.onMenuShareTimeline(shareData)

            })
            wx.error(function (err) {
                console.log(err);
                // config信息验证失败会执行error函数，如签名过期导致验证失败，
                // 具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，
                // 对于SPA可以在这里更新签名。
                // alert('分享失败')
            })
        }, function (res) {
            alert(res)
            console.log(parseInt(res.code))
        })
    }
}

export default wxshare;