import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pageTitle: '',
  },
  getters: {
    getPageTitle(state) {
      return state.pageTitle
    },
  },
  mutations: {
    setPageTitle(state, name) {
      state.pageTitle = name
    },
  },
  actions: {
    setTitleFunc(context, name) {
      context.commit('setPageTitle', name)
    },
  }
})