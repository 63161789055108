import Vue from 'vue'
import Router from 'vue-router'
import tool from '@/utils'

// 移动页
const MiniHome = () => import('@/mini/pages/home')
const MiniSpecialist = () => import('@/mini/pages/specialist')
const MiniMain = () => import('@/mini/pages/main')
const DoctorDetail = () => import('@/mini/pages/doctorDetail')
const Schedule = () => import('@/mini/pages/schedule')
const ScheduleDetail = () => import('@/mini/pages/scheduleDetail')
const MiniInvitationLetter = () => import('@/mini/pages/invitationLetter/index.vue')
const MiniBasicInfo = () => import('@/mini/pages/basicInfo/index.vue')
const MiniOrganization = () => import('@/mini/pages/organization/index.vue')
const MiniExpertIntroduction = () => import('@/mini/pages/expertIntroduction/index.vue')
const MiniCreditInfo = () => import('@/mini/pages/creditInfo/index.vue')
const MiniRegistrationGuide = () => import('@/mini/pages/registrationGuide/index.vue')
const MiniPlayback = () => import('@/mini/pages/playback/index.vue')
const MiniCloudHall = () => import('@/mini/pages/cloudHall/index.vue')

// pc 页
const PcHome = () => import('@/pc/pages/home')
const MeetingInfo = () => import('@/pc/pages/meetingInfo')
const BasicInfo = () => import('@/pc/pages/basicInfo')
const InvitationLetter = () => import('@/pc/pages/invitationLetter')
const Organization = () => import('@/pc/pages/organization')
const RegistrationGuide = () => import('@/pc/pages/registrationGuide')
const CreditInfo = () => import('@/pc/pages/creditInfo')
const ContactUs = () => import('@/pc/pages/contactUs')
const MeetingNotify = () => import('@/pc/pages/meetingNotify')
const PCSchedule = () => import('@/pc/pages/pcschedule')
const Playback = () => import('@/pc/pages/playback')
const Experts = () => import('@/pc/pages/experts')
const CloudHall = () => import('@/pc/pages/cloudHall')
const ExpertDetail = () => import('@/pc/pages/expertDetail')
const PCScheduleDetail = () => import('@/pc/pages/pcscheduleDetail')

Vue.use(Router)

const router = new Router({
  // mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/mini/home'
    },
    {
      path: '/mini/home',
      component: MiniHome
    },
    {
      path: '/mini/photoLive',
      redirect: '/mini/home'
    },
    {
      path: '/mini/meetingLive',
      redirect: '/mini/home'
    },
    {
      path: '/mini/main',
      component: MiniMain,
      children: [
        {
          path: "specialist",
          component: MiniSpecialist
        },
        {
          path: "doctorDetail/:doctorId",
          name: "DoctorDetail",
          component: DoctorDetail
        },
        {
          path: "schedule",
          component: Schedule
        },
        {
          path: "scheduleDetail/:scheduleId",
          name: "ScheduleDetail",
          component: ScheduleDetail
        },
        {
          path: "miniInvitationLetter",
          name: "MiniInvitationLetter",
          component: MiniInvitationLetter
        },
        {
          path: 'miniBasicInfo',
          name: "MiniBasicInfo",
          component: MiniBasicInfo
        },
        {
          path: 'miniExpertIntroduction',
          name: 'MiniExpertIntroduction',
          component: MiniExpertIntroduction
        },
        {
          path: 'miniOrganization',
          name: 'MiniOrganization',
          component: MiniOrganization
        },
        {
          path: 'miniCreditInfo',
          name: 'MiniCreditInfo',
          component: MiniCreditInfo
        },
        {
          path: 'miniRegistrationGuide',
          name: 'MiniRegistrationGuide',
          component: MiniRegistrationGuide
        },
        {
          path: 'miniPlayback',
          name: "MiniPlayback",
          component: MiniPlayback
        },
        {
          path: 'miniCloudHall',
          name :"MiniCloudHall",
          component: MiniCloudHall
        }
      ]
    },
    {
      path: '/pc',
      redirect: '/pc/home/'
    },
    {
      path: "/pc/home",
      redirect: '/pc/home/meetinginfo'
    },
    {
      path: '/pc/home',
      component: PcHome,
      children: [
        {
          path: 'photoLive',
          redirect: 'meetinginfo'
        },
        {
          path: 'meetingLive',
          redirect: 'meetinginfo'
        },
        {
          path: 'meetinginfo',
          component: MeetingInfo
        },
        {
          path: 'basicinfo',
          component: BasicInfo
        },
        {
          path: 'invitationletter',
          component: InvitationLetter
        },
        {
          path: 'organization',
          component: Organization
        },
        {
          path: 'registrationGuide',
          component: RegistrationGuide
        },
        {
          path: 'creditInfo',
          component: CreditInfo
        },
        {
          path: 'contactUs',
          component: ContactUs
        },
        {
          path: 'meetingNotify',
          component: MeetingNotify
        },
        {
          path: 'experts',
          component: Experts
        },
        {
          path: 'expertDetail/:doctorId',
          name: "ExpertDetail",
          component: ExpertDetail
        },
        {
          path: 'playback',
          component: Playback
        },
        {
          path: 'cloudHall',
          component: CloudHall
        },
        {
          path: "pcschedule",
          component: PCSchedule
        },
        {
          path: 'pcscheduleDetail/:scheduleId',
          name: "PCScheduleDetail",
          component: PCScheduleDetail
        }
      ]
    },
  ]
})

router.beforeEach((to, from, next) => {
  // console.log(to);
  if (tool.isMobile() && to.fullPath.includes('pc')) {
    // console.log('移动');
    next({ path: '/' })
  } else if (!tool.isMobile() && to.fullPath.includes('mini')) {
    next({ path: '/pc' })
  }
  else {
    next()
  }
})

export default router
