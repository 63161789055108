<template>
  <div id="app">
    <!-- <el-button @click="handleClick">点我</el-button> -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  created() {},
  methods: {
    // handleClick(){
    //   window.location.assign('http://localhost:8080/static/canoon/CANONV9.html')
    // }
  },
  created() {
    this.shareList(
      "https://cdn.821.idoctor.cc/",
      "2021年07月23日-25日",
      "北京医学会放射学分会2021年学术大会"
    );
  },
};
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}
ul,
ol {
  list-style: none;
}
</style>
